import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.map.js";
import { AssetManagementCategoryType, MetricPeriodType, ResultsOpexBudgetRecordsDocument, ResultsOpexPropertiesDocument } from "~/graphql/generated/graphql";
import moment from "moment";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
import { budgetValidRangeFilter, excludePropertyFromResultsFilter, excludeTenancyFromResultsFilter, PropertyDecomissionedFilter, propertyExcludeIdsFilter, propertyPortfolioFilter, TenancyDecomissionedFilter, TenancySoldFilter } from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import { useQuery } from "@vue/apollo-composable";
import { getOpexCategoryData, getPropertiesAverage, getPropertiesBudgetOpexData } from "~/helpers/portfolio/portfolio-opex-helpers";
import { getPropertyArea } from "~/helpers/portfolio/portfolio-property-helpers";
import { useOpexCategoriesQuery } from "./useOpexCategoriesQuery";
import { hasValue } from "~/helpers/common-helpers";
import { useOpexPeriods } from "./useOpexPeriods";
import { budgetIdsWhitelist } from "~/pages/portfolio/components/Expenses/Opexes/helpers";
export var useOpexData = function useOpexData(preferences, variables, reference) {
  var areaUnit = ref();
  var currency = ref();
  var enabled = computed(function () {
    return (!reference || hasValue(preferences.value.referenceMonthOffset)) && (!!variables.value.portfolioId || !!variables.value.categoryId && !!variables.value.propertyId);
  });
  var excludePropertyIds = computed(function () {
    return preferences.value.excludePropertyIds;
  });
  var periods = useOpexPeriods(preferences, {
    reference: reference
  });
  var columns = periods.columns,
    from = periods.from,
    to = periods.to;
  var propsOpexFilters = useQueryFilters({
    budgetFilters: [budgetValidRangeFilter(computed(function () {
      return {
        from: from.value,
        to: to.value
      };
    }))],
    propertyFilters: [propertyPortfolioFilter(variables), propertyExcludeIdsFilter(computed(function () {
      return {
        excludeIds: excludePropertyIds.value
      };
    })), PropertyDecomissionedFilter, excludePropertyFromResultsFilter],
    tenancyFilters: [TenancySoldFilter, TenancyDecomissionedFilter, excludeTenancyFromResultsFilter]
  });
  var propertiesOpexQuery = useQuery(ResultsOpexPropertiesDocument, function () {
    return {
      propertyFilters: propsOpexFilters.value.value.propertyFilters,
      tenancyFilters: propsOpexFilters.value.value.tenancyFilters,
      budgetFilters: propsOpexFilters.value.value.budgetFilters,
      input: {
        categoryType: AssetManagementCategoryType.Opex,
        periodType: MetricPeriodType.Monthly,
        from: from.value,
        to: to.value
      },
      includeBudgets: true
    };
  }, function () {
    return {
      enabled: enabled.value
    };
  });
  var properties = computed(function () {
    var _propertiesOpexQuery$, _propertiesOpexQuery$2;
    return (_propertiesOpexQuery$ = (_propertiesOpexQuery$2 = propertiesOpexQuery.result.value) === null || _propertiesOpexQuery$2 === void 0 ? void 0 : _propertiesOpexQuery$2.assetManagementProperties.items) !== null && _propertiesOpexQuery$ !== void 0 ? _propertiesOpexQuery$ : [];
  });
  var budgetIds = computed(function () {
    var ids = new Set();
    var yearsCovered = new Set();
    columns.value.forEach(function (period) {
      var year = period.year();
      if (yearsCovered.has(year)) return;
      yearsCovered.add(year);
      properties.value.forEach(function (property) {
        var _property$assetManage, _property$assetManage2, _property$assetManage3;
        /**Note Sven: This needs to be changed if we want to handle budgets not conforming to a year */
        var budgets = (_property$assetManage = (_property$assetManage2 = property.assetManagementBudgets) === null || _property$assetManage2 === void 0 ? void 0 : (_property$assetManage3 = _property$assetManage2.items) === null || _property$assetManage3 === void 0 ? void 0 : _property$assetManage3.filter(function (b) {
          return budgetIdsWhitelist.has(b.id) && moment.utc(b.validFrom).year() === year && moment.utc(b.validTo).year() === year;
        })) !== null && _property$assetManage !== void 0 ? _property$assetManage : [];
        var budget = budgets.reduce(function (a, b) {
          return !a || moment.utc(b.createdAt).isAfter(moment.utc(a.createdAt)) ? b : a;
        }, undefined);
        if (budget) ids.add(budget.id);
      });
    });
    return Array.from(ids);
  });
  var budgetFilters = useQueryFilters({
    filters: [function () {
      return {
        matches: [{
          id: [Operator.In, budgetIds.value]
        }]
      };
    }]
  });
  var budgetRecordsQuery = useQuery(ResultsOpexBudgetRecordsDocument, function () {
    return {
      filters: budgetFilters.value.value.filters
    };
  }, function () {
    return {
      enabled: enabled.value && !propertiesOpexQuery.error.value && !propertiesOpexQuery.loading.value && !!properties.value.length
    };
  });
  var budgets = computed(function () {
    var _budgetRecordsQuery$r, _budgetRecordsQuery$r2, _budgetRecordsQuery$r3;
    return (_budgetRecordsQuery$r = (_budgetRecordsQuery$r2 = budgetRecordsQuery.result.value) === null || _budgetRecordsQuery$r2 === void 0 ? void 0 : (_budgetRecordsQuery$r3 = _budgetRecordsQuery$r2.assetManagementBudgets) === null || _budgetRecordsQuery$r3 === void 0 ? void 0 : _budgetRecordsQuery$r3.items) !== null && _budgetRecordsQuery$r !== void 0 ? _budgetRecordsQuery$r : [];
  });
  var opexCategoriesQuery = useOpexCategoriesQuery();
  var opexCategories = computed(function () {
    var _opexCategoriesQuery$;
    var arr = (_opexCategoriesQuery$ = opexCategoriesQuery.result.value) !== null && _opexCategoriesQuery$ !== void 0 ? _opexCategoriesQuery$ : [];
    var categoryId = variables.value.categoryId;
    if (categoryId) arr = arr.filter(function (c) {
      return c.id === categoryId;
    });
    return arr;
  });
  var categoryIds = computed(function () {
    return new Set(opexCategories.value.map(function (c) {
      return c.id;
    }));
  });
  var averageOpexData = computed(function () {
    var opexData = getPropertiesAverage(properties.value);
    areaUnit.value = opexData.entityData.areaUnit;
    return opexData;
  });
  var averageData = computed(function () {
    return getOpexCategoryData(columns.value, averageOpexData.value, getPropertiesBudgetOpexData(properties.value, categoryIds.value, budgets.value), opexCategories.value, true);
  });
  var getPropertyBenchmarkData = function getPropertyBenchmarkData(categories, property, columns) {
    var _property$assetManage4, _property$assetManage5, _property$assetManage6;
    var _getPropertyArea = getPropertyArea(property),
      area = _getPropertyArea.area,
      areaUnit = _getPropertyArea.areaUnit;
    var opexData = {
      cells: property.categoryCells,
      entityData: {
        averageArea: area || 1,
        areaUnit: areaUnit !== null && areaUnit !== void 0 ? areaUnit : "–",
        totalArea: area,
        totalTenancies: (_property$assetManage4 = (_property$assetManage5 = property.assetManagementTenancies) === null || _property$assetManage5 === void 0 ? void 0 : (_property$assetManage6 = _property$assetManage5.items) === null || _property$assetManage6 === void 0 ? void 0 : _property$assetManage6.length) !== null && _property$assetManage4 !== void 0 ? _property$assetManage4 : 0
      },
      entityCount: 1
    };
    var categoriesData = getOpexCategoryData(columns, opexData, getPropertiesBudgetOpexData([property], categoryIds.value, budgets.value), categories, true);
    return {
      property: property,
      categories: categoriesData,
      area: area
    };
  };
  var propertyOpexBenchmarks = computed(function () {
    return properties.value.map(function (property) {
      return getPropertyBenchmarkData(opexCategories.value, property, columns.value);
    });
  });
  var loading = computed(function () {
    return propertiesOpexQuery.loading.value || opexCategoriesQuery.loading.value || budgetRecordsQuery.loading.value;
  });
  var refs = {
    averageData: averageData,
    averageOpexData: averageOpexData,
    budgets: budgets,
    currency: currency,
    areaUnit: areaUnit,
    loading: loading,
    properties: properties,
    propertyOpexBenchmarks: propertyOpexBenchmarks
  };
  return computed(function () {
    return !reference || hasValue(preferences.value.referenceMonthOffset) ? refs : undefined;
  });
};