import moment, { type Moment } from "moment";
import { InjectionKey } from "vue";
import type { Currency } from "~/helpers/apollo/types";
import { arrayLast, objectFromKeys, TranslationKey } from "~/helpers/common-helpers";
import { capitalizeAll, dateRange } from "~/helpers/filter-helpers";
import { NamespacePortfolioOpex } from "~/helpers/user-preferences-helpers";

export const budgetIdsWhitelist = new Set([
  "1d7b19e8-01fd-5658-98fa-11a29a9a995f",
  "d6536a24-3e5d-53e2-9a8a-b65d082438e0",
  "496858a8-8bbf-5341-b05a-6da8f1d08447",
  "e9b2c86b-b5e0-58c6-9640-08e954b2e0ab",
  "30a15a89-20f1-5609-97a5-28a02f83b82e",
  "7ab4524f-499c-55b7-9d1e-0b24aa15bdc7",
  "1ab66d07-5a79-5c4f-aafe-c07d97e7e774",
  "374cda06-1f0a-5923-8740-e94b548e9dc6",
  "a12afaf5-0989-578e-955a-1487e8a127c9",
  "4d40921e-4bde-5e3b-8d47-7a97cbade68f",
  "ab6fea0c-1ffa-5c46-8417-08795ce7fc37",
  "cc0dbfab-12f7-5106-ab71-69122c622163",
  "32c966ef-e172-5a3f-9c8b-d74f7999af5f",
  "09a36845-4074-54b6-b735-a263e912583d",
  "4d1e2bfc-9b40-5d29-bd5b-f82336fd4e97",
  "2fb40bb3-70be-54df-96b9-e7b614353e43",
  "210115ae-3080-5a60-a68e-dbfe964a0ac0",
  "55f4086a-f385-5988-a078-67e263321a7a",
  "b9002824-2050-5493-9a98-efcc7e18b2e2",
  "cfff833e-b6fe-58da-a7ee-10f7486f3db8",
  "84dcb659-b3dd-5a9d-8bbf-6242d11d48ba",
  "dae203e2-cc45-5019-91e4-853ea4ae02a0",
  "6e8fbf02-e264-51dc-b394-93841dfa67e5",
  "406280ce-7d5f-5cdd-85b9-06cdd85f949b",
  "f8f4c5ff-c1d3-53be-a436-4c060cb07b79",
  "81682ffe-03c4-530a-a329-b0f0fb75b821",
  "6a490ef7-be12-50df-bc7d-6156357deccb",
  "f5cbd972-ac01-5529-a014-e30c75289156",
  "460501f2-b2ae-5b4b-adf9-d35bd68a5239",
  "f889d092-dc9a-5b59-958d-c50551198046",
  "807c1653-08e7-5054-8e3f-a51f9222180d",
  "66c2aa5b-06d0-535b-9a14-fb4f6b37e796",
  "733a1e4d-ae01-5946-98c5-7cea76d2808d",
  "03ed5963-13e5-5383-a916-95cd0d350577",
  "2c25b697-62b6-5596-8d3c-ea89fab80fa0",
  "8c429a5d-a764-50a2-9d5d-d541659b0da6",
  "4c4aace9-3aea-591b-8e3f-85cb3b34ca52",
  "c436d80e-f142-5839-914d-b7b13c64e29c",
  "90341b02-9191-5653-b138-67f5b0c6184e",
  "4fd19d40-e6cd-58c1-93ee-d1716caf555b",
  "1b658342-e9ee-50c4-97a3-b1015dc4cc30",
  "0887228a-703b-5e27-a041-f471fe48062d",
  "7721233a-284f-50c4-9bbf-0a1515407dbf",
  "53886211-a85e-5112-ab89-998203c810a2",
  "aba3b141-42ce-54a4-bf34-cbde67e59ed6",
  "eef5ca04-5bf8-569a-8347-3e4a02629549",
  "e9a7eb05-ab57-5218-9928-df551512091f",
  "b6e56563-9bb7-5075-8572-296d9bba12b6",
  "b785c8e5-fbec-5f8c-a82d-0182e1061f60",
  "0fc031f2-a37d-5d7e-8e6a-3eef76118d22",
  "4b937ba5-3c5f-51ed-88d0-a9b3cb0cdb5f",
  "3c3722c0-5276-5a69-9f40-b80ce8601c13",
  "a39554b2-93a4-507b-97d9-22f93e73b9f5",
  "b30b2afd-af1e-5bec-b5b9-31e9138c09c0",
  "7a7f633a-b561-515d-ac8d-8ebd63e2ea2c",
  "06470bfa-8d57-5676-a71f-61c4ecff5654",
  "7a53a3ec-a7cb-5914-9567-e7d3c8049820",
  "a34d4cd2-db2b-50a2-bbe3-6b182cd2cfeb",
  "80bab019-69ab-55e1-b5c4-c5ce87f1b106",
  "a370dfb1-dccd-5626-a772-b2a376c7f13c",
  "9937bed2-8e1b-5bc7-b8ce-a06e0107f4a2",
  "3ec7eb17-d551-577b-be26-482bd7aa90d7",
  "d1d8a4a2-de3d-5621-9682-60fa73974500",
  "a7126beb-9827-5a1a-89c1-9d2e6714485c",
  "0400f872-472d-5208-a47b-58607ecd3066",
  "40e681d1-aa2c-58c5-85bb-56b4fdab23fa",
  "45d879a5-21b5-5d41-ad6d-fc11ceadfa76",
  "952d8652-3060-54f5-8192-ca42a948a105",
  "86ced259-52aa-5c45-af53-065be9c4b7c1",
  "4fa0bd2f-a85d-56de-a7dc-f5e156407a74",
  "9c323a2d-b89f-5b10-b2d9-d46d8267eb09",
  "fcecc8d8-74fb-5dbd-9306-e57df0c30499",
  "e85a5d82-d986-5d7c-9ea4-e8eb9e0a375a",
  "437a2733-941d-5a46-878c-c7c8b1107b20",
  "e684ebfb-bc35-5c13-ac73-0a84d03b83e4",
  "d3b09d28-7de0-5264-8290-8a71f3f5e801",
  "f7880c1e-7070-5c61-b686-cef5be0e4e08",
  "020f4641-6d73-5db1-aa80-cf1f0aeab8a1",
]);

export const getPeriodLabel = (columns: Moment[], periodRangeType: NamespacePortfolioOpex["periodRangeType"]) => {
  const periodFrom = columns[0];
  const periodTo = arrayLast(columns);
  if (!periodFrom || !periodTo) return "";

  switch (periodRangeType) {
    case "yearRolling":
      return capitalizeAll(
        `${dateRange(moment.utc(periodFrom).startOf("month").format("YYYY-MM-DD"), moment.utc(periodTo).endOf("month").format("YYYY-MM-DD"))}`
      );
    case "yearToDate":
      return capitalizeAll(
        `${dateRange(moment.utc(periodFrom).startOf("month").format("YYYY-MM-DD"), moment.utc(periodTo).endOf("month").format("YYYY-MM-DD"))}`
      );
    case "yearWhole":
      return `${dateRange(moment.utc(periodFrom).startOf("year").format("YYYY-MM-DD"), moment.utc(periodTo).endOf("year").format("YYYY-MM-DD"))}`;
  }
};

export type TableOpexesPropertyData = {
  resultNormal: number;
  resultTotal: number;
  budgetNormal: number;
  budgetTotal: number;
  area: number;
  areaUnit: Nullable<string>;
  tenancies: number;
};

export type PortfolioPropertyOpexCategoryData = {
  id: string;
  name: string;
  currency: Currency;

  current: {
    average: TableOpexesPropertyData;
    propertiesData: Map<string, TableOpexesPropertyData>;
  };

  reference?: {
    average: TableOpexesPropertyData;
    propertiesData: Map<string, TableOpexesPropertyData>;
  };
};

export const OpexSettingsInjectionKey = Symbol("opexSettingsInjectionKey") as InjectionKey<{ preferencesClone: NamespacePortfolioOpex }>;

export const periodRangeOptions = [
  { id: "yearWhole", name: "PORTFOLIO_VIEW_OPEX_PERIOD_WHOLE_YEAR" },
  { id: "yearRolling", name: "PORTFOLIO_VIEW_OPEX_PERIOD_LATEST_12" },
  { id: "yearToDate", name: "PORTFOLIO_VIEW_OPEX_PERIOD_YEAR_TO_DATE" },
] as { id: NamespacePortfolioOpex["periodRangeType"]; name: TranslationKey }[];

export type OpexSettings = Omit<NamespacePortfolioOpex, "template" | "templateModified" | "savedTemplates">;

export type OpexSettingsTemplate = {
  id: string;
  name: string;
  settings: OpexSettings;
};

export const opexSavedSettingsFields = [
  "benchmark",
  "excludePropertyIds",
  "includeZeroRows",
  "normaliseBy",
  "periodOffset",
  "periodRangeType",
  "referenceMonthOffset",
  "referencePercentage",
] as const;

export const settingsTemplateOptions = [
  {
    id: "normLatest12",
    name: "normLatest12",
    settings: {
      benchmark: "average.total",
      excludePropertyIds: ["companies"],
      includeZeroRows: false,
      normaliseBy: "area",
      periodOffset: 0,
      periodRangeType: "yearRolling",
      referenceMonthOffset: undefined,
      referencePercentage: false,
    },
  },

  {
    id: "normDevelopment12Percentage",
    name: "normDevelopment12Percentage",
    settings: {
      benchmark: "average.total",
      excludePropertyIds: ["companies"],
      includeZeroRows: false,
      normaliseBy: "area",
      periodOffset: 0,
      periodRangeType: "yearRolling",
      referenceMonthOffset: -12,
      referencePercentage: true,
    },
  },

  {
    id: "budgetYTD",
    name: "budgetYTD",
    settings: {
      benchmark: "budget.diff",
      excludePropertyIds: ["companies"],
      includeZeroRows: false,
      normaliseBy: "area",
      periodOffset: 0,
      periodRangeType: "yearToDate",
      referenceMonthOffset: undefined,
      referencePercentage: false,
    },
  },
] as const satisfies OpexSettingsTemplate[];

export type StandardOpexTemplateId = (typeof settingsTemplateOptions)[number]["id"];

export const OPEX_TEMPLATE_DEFAULT = "normLatest12" as const satisfies StandardOpexTemplateId;

export const getSelectedTemplate = (preferences: NamespacePortfolioOpex | null) =>
  preferences?.savedTemplates.find((template) => template.id === preferences.template) ??
  settingsTemplateOptions.find((template) => template.id === preferences?.template);

export const settingsTemplateOptionsTranslationKeys = {
  budgetYTD: "PORTFOLIO_OPEX_TEMPLATE_BUDGET_YTD",
  normLatest12: "PORTFOLIO_OPEX_TEMPLATE_NORM_ROLLING",
  normDevelopment12Percentage: "PORTFOLIO_OPEX_TEMPLATE_NORM_DEVELOPMENT_12_PERCENTAGE",
} as const satisfies { [k in StandardOpexTemplateId]: TranslationKey };

export const sanitizeOpexSettings = (preferences: OpexSettings | NamespacePortfolioOpex): OpexSettings =>
  objectFromKeys(preferences, ...opexSavedSettingsFields);
